interface CacheExpectations {
  faqItems?: NodeListOf<HTMLElement>
}

const cache: CacheExpectations = {};

/**
 * Saves re-used elements.
 */
const setupCache = () => {
  cache.faqItems = document.querySelectorAll('.passport-learn-more__faq__item');
};

/**
 * Listens for clicks on question elements.
 */
const addEventListeners = () => {
  cache.faqItems && cache.faqItems.forEach((item) => {
    item.addEventListener('click', (e) => toggleAnswer(e));
  });
};

/**
 * Shows or hides the question text and plus/minus icon.
 */
const toggleAnswer = (e: Event) => {
  const exactTarget = e.target as HTMLElement;
  if (exactTarget.classList.contains("passport-learn-more__faq__link")) {
    // if user is clicking on a link within an answer, don't toggle visibility
    return;
  }
  const item = e.currentTarget as HTMLElement;
  const plusSign = item.querySelector('.passport-learn-more__faq__more');
  const minusSign = item.querySelector('.passport-learn-more__faq__less');
  const answer = item.querySelector('.passport-learn-more__faq__item-body');

  plusSign.classList.toggle("is-hidden");
  minusSign.classList.toggle("is-hidden");
  answer.classList.toggle("is-hidden");
}

/**
 * Initializes component.
 */
 const init = (): void => {
  setupCache();
  addEventListeners();
};

export { init };
