import _debounce from 'lodash.debounce';

import { customEvents } from 'scripts/custom-events';

interface CacheExpectations {
  position?: number;
  subnav?: HTMLElement;
  subnavDefault?: HTMLElement;
  subnavMember?: HTMLElement;
  isUnlocalized?: boolean;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = () => {
  cache.position = window.scrollY;
  cache.subnav = document.querySelector('.passport-subnav');
  cache.subnavDefault = document.querySelector('.passport-subnav__ctas');
  cache.subnavMember = document.querySelector(
    '.passport-subnav__member-thank-you'
  );
  cache.isUnlocalized = document
    .querySelector('body')
    .classList.contains('unlocalized');
};

/**
 * Determines if user has scrolled up;
 * if so, we should show the Passport Subnav.
 */
const onWindowScroll = () => {
  const scroll = window.scrollY;
  if (scroll > cache.position && !cache.isUnlocalized) {
    // if user scrolls down, hide the subnav
    cache.subnav.classList.add('subnav-hidden');
  } else {
    // if user scrolls up, show the subnav
    cache.subnav.classList.remove('subnav-hidden');
  }
  cache.position = scroll;
};

/**
 * Handles differing behavior once a user is
 * identified as a Passport member.
 */
const onUserIsPassport = () => {
  cache.subnavMember.classList.remove('is-hidden');
  window.removeEventListener(customEvents.userIsPassport, onUserIsPassport);
};

/**
 * Handles differing behavior once a user is
 * identified as not a Passport member or not signed in.
 */
const onUserIsDefault = () => {
  // un-hide the elements that ask the user to become a member
  cache.subnavDefault.classList.remove('is-hidden');
};

/**
 * Adds events listeners for Passport Subnav.
 */
const addEvents = () => {
  window.addEventListener(customEvents.userIsPassport, onUserIsPassport);
  window.addEventListener(customEvents.userIsNotLoggedIn, onUserIsDefault);
  window.addEventListener(customEvents.userIsNotPassport, onUserIsDefault);
  window.addEventListener('scroll', _debounce(onWindowScroll, 50));
};

/**
 * Init
 */
const init = () => {
  setupCache();
  addEvents();
};

export { init };
