import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'scripts/modules/external-links';
import 'components/page-header/js/page-header';
import 'components/page-footer/page-footer';

import { customEvents } from 'scripts/custom-events';
import { init as localizationEventsInit } from 'scripts/modules/localization-events';
import { init as passportFeaturedShowsInit } from 'components/passport-featured-shows/passport-featured-shows';
import { init as passportFaqInit } from 'components/passport-faq/passport-faq';
import { init as passportSubnavInit } from 'components/passport-subnav/passport-subnav';

interface CacheExpectations {
  heroSubtitleDefault?: HTMLElement;
  heroSubtitleMember?: HTMLElement;
  heroCtaButton?: HTMLElement;
  featureChartFullButton?: HTMLElement;
  featureChartCondensedButton?: HTMLElement;
  finalPromoTextDefault?: HTMLElement;
  finalPromoTextMember?: HTMLElement;
  finalCtaButton?: HTMLElement;
  checkMembershipLink?: HTMLElement;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
 const setupCache = () => {
  cache.heroSubtitleDefault = document.querySelector('.passport-learn-more__hero__subtitle--default');
  cache.heroSubtitleMember = document.querySelector('.passport-learn-more__hero__subtitle--member');
  cache.heroCtaButton = document.querySelector('.passport-learn-more__hero__button');
  cache.featureChartFullButton = document.querySelector('.passport-learn-more__full__become-a-member');
  cache.featureChartCondensedButton = document.querySelector('.passport-learn-more__condensed__become-a-member');
  cache.finalPromoTextDefault = document.querySelector('.passport-learn-more__become-a-member__description--default');
  cache.finalPromoTextMember = document.querySelector('.passport-learn-more__become-a-member__description--member');
  cache.finalCtaButton = document.querySelector('.passport-learn-more__become-a-member__button');
  cache.checkMembershipLink = document.querySelector('.passport-learn-more__become-a-member__check-membership-link');
}

/**
 * Handles differing page behavior once a user is
 * identified as a Passport member.
 */
const onUserIsPassport = () => {
  // If a Passport member is already signed in and is
  // intentionally browsing the page, we update the page to
  // un-hide the Passport member specific page elements
  cache.heroSubtitleMember.classList.remove('is-hidden');
  cache.finalPromoTextMember.classList.remove('is-hidden');
  window.removeEventListener(customEvents.userIsPassport, onUserIsPassport);
}


/**
 * Handles differing page behavior once a user is
 * identified as not a Passport member or not signed in.
 */
const onUserIsDefault = () => {
  // un-hide all the page elements that ask the user to become a member
  cache.heroSubtitleDefault.classList.remove('is-hidden');
  cache.heroCtaButton.classList.remove('is-hidden');
  cache.featureChartFullButton.classList.remove('is-hidden');
  cache.featureChartCondensedButton.classList.remove('is-hidden');
  cache.finalPromoTextDefault.classList.remove('is-hidden');
  cache.finalCtaButton.classList.remove('is-hidden');
  cache.checkMembershipLink.classList.remove('is-hidden');
}

/**
 * Adds events listeners for Passport Learn More.
 */
const addEvents = () => {
  window.addEventListener(customEvents.userIsPassport, onUserIsPassport);
  window.addEventListener(customEvents.userIsNotLoggedIn, onUserIsDefault);
  window.addEventListener(customEvents.userIsNotPassport, onUserIsDefault);
};

/**
 * Init
 */
const init = () => {
  setupCache();
  addEvents();
};

localizationEventsInit();
passportFeaturedShowsInit();
passportFaqInit();
passportSubnavInit();
init();
